import Vue from 'vue';
import { Button ,row,col,Card,backtop,Icon,divider,image } from 'element-ui';

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(Button);
Vue.use(row);
Vue.use(col);
Vue.use(Card);
Vue.use(backtop);
Vue.use(Icon);
Vue.use(divider);
Vue.use(image);
