<template>
  <div id="app">
    <div class="grid-content bg-purple">
      <img :src="heads.wx">
    </div>
    <div style="margin-bottom: 10px"></div>
    <el-divider content-position="center">产品展示</el-divider>
    <div class="flex-container">
      <div class="flex-item col-card" v-for="(item, index) in list" :key="index">
        <el-card :body-style="{ padding: '0px' }">
          <el-image :src="item.image" class="image" lazy></el-image>
          <!-- <img :src=item.image class="image"> -->
          <div style="padding: 14px;">
            <span>{{ item.name }}</span>
            <div class="bottom clearfix">
              <time class="price">¥{{ item.price.toFixed(2) }}</time>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-backtop></el-backtop>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      list:[],
      heads:{
        wx:'',
        wxurl:''
      }
    }
  },
  created() {
    this.GetHeads();
    this.Getproducts();
  },
  methods: {
    async GetHeads() {
      await axios.get('./config/head.xml').then(response => {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(response.data, "text/xml");
        this.heads.wx = "./assets/head/" + xmlDoc.getElementsByTagName("wx")[0].getElementsByTagName("image")[0].childNodes[0].nodeValue;  
        document.title = xmlDoc.getElementsByTagName("title")[0].childNodes[0].nodeValue;
        // this.heads.wxurl = xmlDoc.getElementsByTagName("wx")[0].getElementsByTagName("url")[0].childNodes[0].nodeValue;   
      });
    },
    async Getproducts() {
      await axios.get('./config/product.xml').then(response => {
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(response.data, "text/xml");
        let products = xmlDoc.getElementsByTagName("product");
        for (let i = 0; i < products.length; i++) {
          let product = {
            name: products[i].getElementsByTagName("name")[0].childNodes[0].nodeValue,
            price: parseFloat(products[i].getElementsByTagName("price")[0].childNodes[0].nodeValue),
            image: "./assets/product/" + products[i].getElementsByTagName("image")[0].childNodes[0].nodeValue
          };
          this.list.push(product);
        }
      });
    },
    handleOpenUrl(url){
      window.open(url);
    }
  },
  mounted() {
    document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
        event.preventDefault();
      }
    });
    document.addEventListener('gesturestart', function (event) {
    event.preventDefault();
    });
  }
}

</script>
<style>
html{
  font-size: 15px;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;  /* 根据需要调整 */
}

.flex-item {
  flex: 1 0 200px;  /* 根据需要调整 */
  margin-bottom: 10px;
  margin-right: 10px;
  min-width: 200px;  /* 根据需要调整 */
  max-width: 400px;  /* 根据需要调整 */
}
@media (max-width: 768px) {
  .flex-item {
    flex: 0 0 40%;
    min-width: 47%;
    max-width: 40%;
  }
}
.el-backtop {
  bottom: 40px;
  right: 40px;
}
  .col-card {
    margin-bottom: 10px;
  }
  .price {
    font-size: 13px;
    color: red;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    height: 11.07rem;
    display: block;
    /* object-fit: cover; */
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>